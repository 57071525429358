import {bus} from "@/helpers/bus";
import Dh from "@/helpers/date.helper";

import config from "@/config";
const server_base = config[config.stage].ws_server;

import GeneratePDFModal from "../GeneratePDFModal";
import CreateModal from "../CreateModal";
import CustomFilter from "@/components/Filters/Custom/index";
import Timeframe from "@/components/Filters/Timeframe/index";
import Agents from "@/components/Filters/Agent/index";
import Sources from "@/components/Filters/Source/index";

import AppraisalService from "@/services/appraisal.service";
const appraisalService = new AppraisalService();

export default {
  name: 'AppraisalInternalList',

  components: {
    GeneratePDFModal,
    CreateModal,
    CustomFilter,
    Timeframe,
    Agents,
    Sources,
  },

  data() {
    return {
      appraisals: [],

      createModal: {
        show: false,
        appraisal: null
      },
      generatePdfModal: {
        show: false,
        customerId: null,
        carVin: null,
      },
      purchaseModal: {
        show: false,
        appraisal: null
      },

      isLoading: false,

      searchTerm: '',
      perPage: 50,
      totalRows: 0,
      currentPage: 1,

      // Filters
      source: null,
      agentId: '',
      dateRange: {
        from: '',
        to: '',
      },
    }
  },

  async mounted() {
    await this.list();
  },

  methods: {
    async list(page) {
      this.currentPage = page || 1;
      const skip = this.perPage * (this.currentPage - 1);

      const filters = {
        purchased: this.purchased,
        source: this.source?.Name || this.source,
        search: this.searchTerm,
        agent: this.agentId,
        from: this.dateRange.from,
        to: this.dateRange.to,
      }

      this.appraisals = [];

      const res = await appraisalService.list(this.perPage, skip, filters);
      if (res && !res.error) {
        this.appraisals = res.data.appraisals;
        this.totalRows = res.data.count;
      }
    },

    showVehicle(vehicle) {
      return `${vehicle.Year} ${vehicle.Make} ${vehicle.Model} ${vehicle.Trim}`;
    },

    async showPreviewModal(appraisal) {
      this.generatePdfModal.show = true;
      this.generatePdfModal.customerId = appraisal.Customer._id;
      this.generatePdfModal.carVin = appraisal.Vin;
    },

    async showCreateModal(appraisal) {
      this.createModal.show = true;
      this.createModal.appraisal = appraisal || null;
    },

    async showPurchaseModal(appraisal) {
      this.purchaseModal.show = true;
      this.purchaseModal.appraisal = appraisal;
    },

    closeModal() {
      this.generatePdfModal.show = false;
      this.generatePdfModal.customerId = null;
      this.generatePdfModal.carVin = null;
      this.createModal.show = false;
      this.createModal.appraisal = null;
      this.purchaseModal.show = false;
      this.purchaseModal.appraisal = null;
      this.isLoading = false;
    },

    async onAppraisalCreated() {
      this.closeModal();
      await this.list();
    },

    onAppraisalUpdated(appraisal) {
      this.closeModal();
      const index = this.appraisals.findIndex(el => el._id === appraisal._id);
      if (index > -1) {
        this.appraisals[index] = appraisal;
      }
    },

    async purchaseCar() {
      this.isLoading = true;

      const {_id, PurchasePrice} = this.purchaseModal.appraisal;

      const res = await appraisalService.purchaseCar(_id, PurchasePrice);
      if (res && !res.error) {
        const appraisal = res.data.appraisal;

        const index = this.appraisals.findIndex(el => appraisal._id === el._id);
        if (index > -1) {
          this.appraisals[index] = appraisal;
        }

        this.$notify({type: 'success', message: this.$t('purchase_success')});

        this.closeModal();
      }
    },

    userAvatar(userId) {
      return `${server_base}/api/files/avatar/${userId}`;
    },

    tooltipDate(date) {
      return Dh.customFormat(date, 'D MMM YYYY, h:mm A');
    },

    async setPerPage(num) {
      this.perPage = +(num);
      bus.$emit('HIDE_ALL_MODALS');
      await this.list();
    },

    async next() {
      await this.list(this.currentPage + 1);
    },

    async prev() {
      await this.list(this.currentPage - 1);
    },

    //region Filters
    onInitSource(value) {
      this.source = value;
    },

    onInitDate(value) {
      this.dateRange = value;
    },

    onInitAgent(value) {
      this.agentId = value;
    },

    async onSelectDate({from, to}) {
      this.dateRange.from = from;
      this.dateRange.to = to;
      await this.list();
    },

    async onSelectAgent(agentId) {
      this.agentId = agentId;
      await this.list();
    },

    async onSelectSource(source) {
      this.source = source;
      await this.list();
    },

    async onSelectPurchase(status) {
      if (status) {
        this.purchased = status === 'Purchased';

      } else {
        this.purchased = null;
      }

      await this.list();
    },
    //endregion
  },

  watch: {
    async searchTerm() {
      await this.list();
    },
  },

  filters: {
    avatar(obj) {
      let letters = '';

      if (obj?.FirstName && obj?.LastName) {
        letters = `${obj.FirstName[0]}${obj.LastName[0]}`;
      } else if (obj?.FirstName && !obj?.LastName) {
        letters = obj.FirstName[0];
      } else if (!obj?.FirstName && obj?.LastName) {
        letters = obj.LastName[0];
      } else letters = 'G';

      return letters;
    },

    formatDate(date) {
      if (date) {
        return Dh.formatDate(date);
      }
      return 'N/A'
    },

    fullName(user) {
      return `${user.FirstName} ${user.LastName}`;
    },

    carDescription(car) {
      return `${car.Year} ${car.Make} ${car.Model}`;
    },
  }
}