import LegalDocumentService from "@/services/legal.document.service";
const legalDocumentService = new LegalDocumentService();

export default {
  name: 'AppraisalInternalList',

  props: {
    onClose: Function,
    customerId: null,
    carVin: null,
  },

  data() {
    return {
      showSelectDocuments: true,
      showPDFPreview: false,
      pdfDataBase64: null,
      isLoading: false,

      documents: [],
    }
  },

  async mounted() {
    await this.listLegalDocuments();
  },

  computed: {
    documentsSelected() {
      return this.documents.filter(doc => doc.selected).map(doc => doc.value);
    },
  },

  methods: {
    async showGeneratePDF() {
      this.isLoading = true;

      await this.generatePDF();

      this.showSelectDocuments = false;
      this.showPDFPreview = true;
      this.isLoading = false;
    },

    async listLegalDocuments() {
      const filters = {
        sections: ['appraisal']
      }

      const res = await legalDocumentService.list('', '', filters);
      if (res && !res.error) {
        this.documents = res.data.documents.map(doc => ({
          selected: false,
          text: doc.File.OriginalName,
          value: doc.File.Name,
        }))
      }
    },

    async generatePDF() {
      const filters = {
        customerId: this.customerId,
        carVin: this.carVin
      }

      const res = await legalDocumentService.fillPDF(this.documentsSelected, filters);
      if (res && !res.error) {
        this.pdfDataBase64 = `data:application/pdf;base64,${res.data.pdfFile}`;
      }
    },

    closeModal() {
      if (this.onClose) this.onClose();
    },
  },
}


