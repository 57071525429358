import Dh from "@/helpers/date.helper";
import States from '@/helpers/states';

import Validations from './validation';
import Errors from './Errors';

import LeadTracking from '@/components/LeadTracking/index';

import HttpClient from "@/helpers/http.client";
import AppraisalService from "@/services/appraisal.service";
import InventoryService from "@/services/inventory.service";
import CustomerService from "@/services/customer.service";
import LienHolderService from '@/services/lienholder.service';

const provider = new HttpClient();
const appraisalService = new AppraisalService(provider);
const inventoryService = new InventoryService(provider);
const customerService = new CustomerService(provider);
const lienHolderService = new LienHolderService(provider);

export default {
  name: 'AppraisalInternalList',

  props: {
    appraisal: null,
    onClose: Function,
    onCreated: Function,
    onUpdated: Function,
  },

  components: {
    LeadTracking
  },

  data() {
    return {
      tab: 'customer',
      searchCustomer: '',
      searchVehicle: '',
      searchLienHolder: '',
      searchTermTimeout: null,
      isLoading: false,
      visible: false,

      agents: [],
      customers: [],
      vehicles: [],
      vehicleMakes: [],
      vehicleModels: [],
      vehicleBodies: [],
      vehicleTrims: [],
      lienHolders: [],
      states: States,

      thisAppraisal: {
        LienholderName: '',
        LienholderAddress: '',
        LienholderPhone: '',
        LienholderAccount: '',
        LienholderPayOffDate: '',
        LienholderQuoteVerifiedViaPhone: false,
        LienholderQuoteVerifiedViaNotice: false,
        LienholderVehicleTypeRetail: false,
        LienholderVehicleTypeLease: false,

        FirstName: '',
        LastName: '',
        Email: '',
        Phone: '',
        DriverLicense: '',

        Address: '',
        City: '',
        State: '',
        ZipCode: '',

        Vin: '',
        Make: '',
        Model: '',
        Year: '',
        Trim: '',
        Body: '',
        ExteriorColor: '',

        ExpectedOfferPrice: '',
        PurchasePrice: '',
        PayOffAmountOwed: '',
        NegativeEquityDue: '',
        PositiveEquityDue: '',

        PreviousOfferSource: '',
        PreviousOfferPrice: '',

        Mileage: '',
        OdometerReadDate: '',
        IsFiveDigitOdometer: false,
        IsSixDigitOdometer: false,
        OdometerReflectsActualMileage: false,
        OdometerExceedsMechanicalLimits: false,
        OdometerDiscrepancy: false,

        TitleStatus: '',
        TitleNumber: '',
        TitleIssueDate: '',

        IsMotorVehicle: false,
        IsMobileHome: false,
        IsVessel: false,
        IsVesselUnTitledTrailer: false,
        IsVesselTitledTrailer: false,

        KeysQuantity: '',
        HasAccident: '',
        InteriorCondition: '',
        SeatsMaterial: '',
        SmokedInside: '',
        ExteriorCondition: '',
        HailDamage: '',
        CurrentTiresMileage: '',
        Modifications: [],
        Runnable: '',
        WarningLights: '',
        FloodFireDamage: '',
        OtherMechanicalConditions: '',
        SellTiming: '',
      },
    }
  },

  validations: Validations,

  computed: {
    ...Errors,

    editMode() {
      return this.appraisal !== null;
    },

    createMode() {
      return this.appraisal === null;
    },

    customerListVisible() {
      return this.searchCustomer.length && this.customers.length;
    },

    vehicleListVisible() {
      return this.searchVehicle.length && this.vehicles.length;
    },

    lienHolderListVisible() {
      return this.searchLienHolder.length && this.lienHolders.length;
    },

    invalidCustomer() {
      return this.$v.thisAppraisal.FirstName.$anyError || this.$v.thisAppraisal.LastName.$anyError ||
          this.$v.thisAppraisal.Email.$anyError || this.$v.thisAppraisal.Phone.$anyError ||
          this.$v.thisAppraisal.Address.$anyError || this.$v.thisAppraisal.City.$anyError ||
          this.$v.thisAppraisal.State.$anyError || this.$v.thisAppraisal.ZipCode.$anyError;
    },

    invalidVehicle() {
      return this.$v.thisAppraisal.Vin.$anyError || this.$v.thisAppraisal.Make.$anyError ||
          this.$v.thisAppraisal.Model.$anyError || this.$v.thisAppraisal.Year.$anyError ||
          this.$v.thisAppraisal.Trim.$anyError || this.$v.thisAppraisal.Body.$anyError ||
          this.$v.thisAppraisal.ExteriorColor.$anyError;
    },
  },

  async mounted() {
    if (this.appraisal) {
      const appraisal = {...this.appraisal};

      if (appraisal.Make) {
        this.vehicleMakes.push(appraisal.Make);
      }
      if (appraisal.Model) {
        this.vehicleModels.push(appraisal.Model);
      }
      if (appraisal.Trim) {
        this.vehicleTrims.push(appraisal.Trim);
      }
      if (appraisal.Body) {
        this.vehicleBodies.push(appraisal.Body);
      }

      this.thisAppraisal = this.formatAppraisal(appraisal);
    }
  },

  methods: {
    formatAppraisal(appraisal) {
      let _appraisal = {...appraisal};

      if (appraisal.OdometerReadDate) {
        _appraisal.OdometerReadDate = Dh.customFormat(appraisal.OdometerReadDate);
      }
      if (appraisal.LienholderPayOffDate) {
        _appraisal.LienholderPayOffDate = Dh.customFormat(appraisal.LienholderPayOffDate);
      }
      if (appraisal.TitleIssueDate) {
        _appraisal.TitleIssueDate = Dh.customFormat(appraisal.TitleIssueDate);
      }

      return _appraisal;
    },

    async create() {
      await this.$v.$touch();
      if (this.$v.$invalid) return;

      this.isLoading = true;

      const res = await appraisalService.create(this.thisAppraisal);
      if (res && !res.error) {
        if (this.onCreated) this.onCreated(this.thisAppraisal);
      }

      this.isLoading = false;
    },

    async update() {
      await this.$v.$touch();
      if (this.$v.$invalid) return;

      this.isLoading = true;

      const res = await appraisalService.update(this.thisAppraisal);
      if (res && !res.error) {
        if (this.onUpdated) this.onUpdated(this.thisAppraisal);
      }

      this.isLoading = false;
    },

    async listCustomers() {
      const res = await customerService.list(3, 0, this.searchCustomer);
      if (res && !res.error) {
        if (this.searchCustomer === '') return;
        this.customers = res.data.customers;
        this.visible = true;
      }
    },

    async listLienHolders() {
      const filters = {
        search: this.searchLienHolder
      }

      const res = await lienHolderService.list('', '', filters);
      if (res && !res.error) {
        this.lienHolders = res.data.lienHolders;
        this.visible = true;
      }
    },

    async listVehicles() {
      const res = await inventoryService.DecodeVin(this.searchVehicle);
      if (res && !res.error) {
        if (this.searchVehicle === '') return;
        this.vehicles = Array.isArray(res.data) ? res.data : [res.data];
        this.visible = true;

        const vehicleMake = this.vehicles.at(0).make;

        Promise.all([
          this.getVehicleModels(vehicleMake),
          this.getVehicleBodies(vehicleMake),
          this.getVehicleTrims(vehicleMake),
        ]);
      }
    },

    async getVehicleMakes() {
      const res = await inventoryService.getRawMakes();
      if (res && !res.error) {
        this.vehicleMakes = res.data;
      }
    },

    async getVehicleModels(make) {
      const res = await inventoryService.getRawModels({make});
      if (res && !res.error) {
        this.vehicleModels = res.data;
      }
    },

    async getVehicleBodies(make) {
      const res = await inventoryService.getRawBodies({make});
      if (res && !res.error) {
        this.vehicleBodies = res.data;
      }
    },

    async getVehicleTrims(make) {
      const res = await inventoryService.getRawTrims({make});
      if (res && !res.error) {
        this.vehicleTrims = res.data;
      }
    },

    onVesselSelected(type) {
      this.thisAppraisal.IsMotorVehicle = false;
      this.thisAppraisal.IsMobileHome = false;
      this.thisAppraisal.IsVessel = false;
      this.thisAppraisal.IsVesselTitledTrailer = false;
      this.thisAppraisal.IsVesselUnTitledTrailer = false;
      this.thisAppraisal[type] = true;
    },

    onOdometerDigitSelected(type) {
      this.thisAppraisal.IsFiveDigitOdometer = false;
      this.thisAppraisal.IsSixDigitOdometer = false;
      this.thisAppraisal[type] = true;
    },

    onOdometerStatusSelected(type) {
      this.thisAppraisal.OdometerReflectsActualMileage = false;
      this.thisAppraisal.OdometerExceedsMechanicalLimits = false;
      this.thisAppraisal.OdometerDiscrepancy = false;
      this.thisAppraisal[type] = true;
    },

    onVehicleTypeSelected(type) {
      this.thisAppraisal.LienholderVehicleTypeRetail = false;
      this.thisAppraisal.LienholderVehicleTypeLease = false;
      this.thisAppraisal[type] = true;
    },

    onTrackingSelected(tracking) {
      this.thisAppraisal.TrackingSource = tracking._id;
    },

    onCustomerClick(customer) {
      this.thisAppraisal.Customer = customer._id;

      if (customer.FirstName) {
        this.thisAppraisal.FirstName = customer.FirstName;
      }
      if (customer.LastName) {
        this.thisAppraisal.LastName = customer.LastName;
      }
      if (customer.Email) {
        this.thisAppraisal.Email = customer.Email;
      }
      if (customer.Phone) {
        this.thisAppraisal.Phone = customer.Phone;
      }
      if (customer.License) {
        this.thisAppraisal.DriverLicense = customer.License;
      }
      if (customer.Address) {
        this.thisAppraisal.Address = customer.Address;
      }
      if (customer.City) {
        this.thisAppraisal.City = customer.City;
      }
      if (customer.State) {
        this.thisAppraisal.State = customer.State;
      }
      if (customer.Zip) {
        this.thisAppraisal.ZipCode = customer.Zip;
      }

      this.clearSearch();
    },

    onVehicleClick(vehicle) {
      this.clearSearch();

      this.thisAppraisal.Vin = vehicle.vin;
      this.thisAppraisal.Make = vehicle.make;
      this.thisAppraisal.Year = vehicle.year;
      this.thisAppraisal.Model = vehicle.model;
      this.thisAppraisal.Trim = vehicle.short_trim || vehicle.trim;
      this.thisAppraisal.Body = vehicle.body_type;

      this.vehicleModels.push(vehicle.model);
      this.vehicleTrims.push(vehicle.short_trim || vehicle.trim);
      this.vehicleBodies.push(vehicle.body_type);
    },

    onLienHolderClick(lienHolder) {
      if (lienHolder._id) {
        this.thisAppraisal.LienholderId = lienHolder._id;
      }
      if (lienHolder.Name) {
        this.thisAppraisal.LienholderName = lienHolder.Name;
      }

      this.clearSearch();
    },

    lienHolderImageURL(lienHolder) {
      return this.$s3_url() + 'lienHolder/' + lienHolder;
    },

    clearSearch() {
      this.searchCustomer = '';
      this.searchVehicle = '';
      this.searchLienHolder = '';
      this.customers = [];
      this.vehicles = [];
      this.lienHolders = [];
      this.visible = false;
    },

    closeModal() {
      if (this.onClose) this.onClose();
    },
  },

  watch: {
    tab(tab) {
      if (tab === 'vehicle') {
        if (this.vehicleMakes.length <= 2) {
          this.getVehicleMakes();

          if (this.appraisal?.Make) {
            this.getVehicleModels(this.appraisal.Make);
            this.getVehicleTrims(this.appraisal.Make);
            this.getVehicleBodies(this.appraisal.Make);
          }
        }
      }
    },

    async searchCustomer() {
      clearTimeout(this.searchTermTimeout);

      if (this.searchCustomer === '') {
        this.visible = false;
        return;
      }

      this.searchTermTimeout = setTimeout(() => {
        this.listCustomers();
      }, 500); // 500 ms delay
    },

    async searchVehicle() {
      clearTimeout(this.searchTermTimeout);

      if (this.searchVehicle === '') {
        this.visible = false;
        return;
      }

      this.searchTermTimeout = setTimeout(() => {
        this.listVehicles();
      }, 500); // 500 ms delay
    },

    async searchLienHolder() {
      clearTimeout(this.searchTermTimeout);

      if (this.searchLienHolder === '') {
        this.visible = false;
        return;
      }

      this.searchTermTimeout = setTimeout(() => {
        this.listLienHolders();
      }, 500); // 500 ms delay
    },

    'thisAppraisal.Email'(newEmail, oldEmail) {
      if (oldEmail.length > 0) {
        delete this.thisAppraisal.Customer;
      }
    },

    'thisAppraisal.Phone'(newPhone, oldPhone) {
      if (oldPhone.length > 0) {
        delete this.thisAppraisal.Customer;
      }
    }
  },

  filters: {
    avatar(obj) {
      let letters = '';

      if (obj?.FirstName && obj?.LastName) {
        letters = `${obj.FirstName[0]}${obj.LastName[0]}`;
      } else if (obj?.FirstName && !obj?.LastName) {
        letters = obj.FirstName[0];
      } else if (!obj?.FirstName && obj?.LastName) {
        letters = obj.LastName[0];
      } else letters = 'G';

      return letters;
    },
  }
}